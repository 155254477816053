@layer primeng {
  .info-icon,
  .info-icon-large {
    font-size: 1.125rem;
    color: theme(colors.r1-blue.800);
  }

  .p-inputnumber,
  .p-inputnumber-input {
    inline-size: 100%;
  }

  .p-select,
  .p-multiselect,
  .p-autocomplete {
    inline-size: 100%;
  }

  .p-select-label {
    align-content: center;
  }

  .p-datatable-table-container {
    scrollbar-width: thin;
  }

  .p-toast-summary:empty {
    display: none;
  }
}
